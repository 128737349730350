import { gql } from '@apollo/client';

export const SEND_EMAIL_RECEIPT = gql`
  mutation SendEmailReceipt(
    $carrierUuid: String
    $driverUuid: String
    $format: EmailFormats
    $recipients: [String]!
    $transactionUuid: [String]
  ) {
    sendEmail(
      carrierUuid: $carrierUuid
      driverUuid: $driverUuid
      format: $format
      recipients: $recipients
      transactionUuid: $transactionUuid
    )
  }
`;
